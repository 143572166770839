<template>
  <v-row class="pa-0 ma-0">
    <v-col cols="12">
      <apexchart type="donut" :options="chartCliGeo" :series="serieCliGeo"></apexchart>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'GraficaGeolocalizados',
  props:{
    datos: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    totalClients () {
      const total_geo = this.datos.length > 0 ? this.datos.reduce((sum, cli) => sum + parseInt(cli.clientes_geolocalizados.trim()), 0) : 0;

      const total_cli = this.datos.length > 0 ? this.datos.reduce((sum, cli) => sum + parseInt(cli.total_clientes.trim()), 0) : 0;
      return {
        geolocalizados: total_geo, 
        no_geolocalizados: total_cli - total_geo,
      }
    },
    serieCliGeo() {
     const total_geo = this.datos.length > 0 ? this.datos.reduce((sum, cli) => sum + parseInt(cli.clientes_geolocalizados), 0) : 0;

      const total_cli = this.datos.length > 0 ? this.datos.reduce((sum, cli) => sum + parseInt(cli.total_clientes), 0) : 0;

      return [total_geo, total_cli - total_geo]
    },
    chartCliGeo() {
      return {
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
            fontSize: '16px',
            fontWeight: 600,
        },
        labels: ['Geolocalizados', 'Sin Geolocalización'],
        colors: ['#2CC09C', '#FB8C00'],
        chart: {
          type: 'donut',
        },
        dataLabels: {
          formatter: (val, opts) => {
            const name = opts.w.config.series[opts.seriesIndex];
            return [val.toFixed(1) + '%', name]
          }
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name:{
                  show: true,
                  fontSize: '22px',
                  color: '#003b49'
                },
                value:{
                  show: true,
                  fontSize: '28px',
                  color: '#003b49',
                   fontWeight: 700,
                },
                total:{
                  show: true,
                  label: 'TOTAL CLIENTES'
                }
              }
            }
          }
        }
      };
    },
  }
}
</script>